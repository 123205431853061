import axiosInstance, { WWW_BASE_URL } from './server';

export const uploadAndRemoveBackground = async (file: File): Promise<Blob> => {
	const formData = new FormData();
	formData.append('file', file);
	const response = await axiosInstance.post('/remove-bg/', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		responseType: 'blob', // Expecting a blob response
	});
	return response.data;
};

export const uploadBlobToCDN = async (file: Blob, filename: string): Promise<void> => {
	const formData = new FormData();
	formData.append('file', file, filename);
	const response = await axiosInstance.post(`${WWW_BASE_URL}/upload`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return response.data
};

export const updateFileOnCDNWithBlob = async (file: Blob, filename: string): Promise<void> => {
	const formData = new FormData();
	formData.append('file', file, filename);
	const response = await axiosInstance.post(`${WWW_BASE_URL}/update/${filename}`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return response.data;
};

export const uploadFileToCDN = async (file: File): Promise<void> => {
	const formData = new FormData();
	formData.append('file', file);
	const response = await axiosInstance.post(`${WWW_BASE_URL}/upload`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return response.data
};

export const updateFileOnCDN = async (file: File, filename: string): Promise<void> => {
	const formData = new FormData();
	formData.append('file', file);
	const response = await axiosInstance.post(`${WWW_BASE_URL}/update/${filename}`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return response.data
};

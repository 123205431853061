import React, { useState } from 'react';
import {Dialog, Menu, Switch, Transition} from '@headlessui/react';
import {Categories, categoriesFromJSON, categoriesToJSON, Listing, MarketplacePlatform} from '../utils/types';
import {BuildingStorefrontIcon, ChevronRightIcon, PhotoIcon} from '@heroicons/react/24/outline';
import { NotificationPopupComponent } from "./NotificationPopup";
import { userStore } from "../state/User";
import {generateUUID, productSizes} from "../utils/data";
import { createListing, updateListing } from "../api/listings";
import { listingsStore } from "../state/Listing";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {PlusCircleIcon, XCircleIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {calculateTotalQuantity, initialInventory as initialInventoryData} from "./CreateInventory";
import {marketplaceImages} from "./ListingImageCloud";
import {Inventory} from "app-ts-types/inventories";
import {createInventory} from "../api/inventories";
import {inventoryStore} from "../state/Inventory";

const initialListing: Listing = {
	platform: MarketplacePlatform.EBAY,
	id: '',
	user: '',
	title: '',
	description: '',
	price: 0,
	currencyCode: 'USD',
	quantity: 1,
	imageUrls: [],
	category: '',
	condition: 'new',
	seller: '',
	location: '',
	shipping: undefined,
	scheduleDateTime: 0,
	createdAt: Date.now(),
	updatedAt: Date.now(),
	status: 'LISTED',
	inventoryId: '',
	listingUrl: '',
	tagsAndKeywords: [],
	SKU: "",
	brand: "",
	color: "",
	sizes: [],
};
const ListingForm = ({ initialData=initialListing, initialInventory=initialInventoryData, onClose }: { initialData?: Listing, initialInventory?:Inventory, onClose: () => void; }) => {
	// if there's no size information available, then let's add one for default size selection
	if (!initialData.sizes || !initialData.sizes.length) {
		initialData.sizes = [{size: '', quantity: 0}]
	}
	const [listing, setListing] = useState(initialData);
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(true);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const [selectedPlatforms, setSelectedPlatforms] = useState<MarketplacePlatform[]>([]);
	const [currentPlatform, setCurrentPlatform] = useState<number>(-1);
	const [customMarketPlaceListing, setCustomMarketPlaceListing] = useState<{ [key: number|string]:Listing}>({"-1": initialData});
	const [currentSizeIndex, setCurrentSizeIndex] = useState(0);
	const [sizeType, setSizeType] = useState<'US' | 'EU'>('US');
	const [openCategory, setOpenCategory] = useState<string | null>(null);
	const [openMenu, setOpenMenu] = useState(false);

	// Merge function
	const mergeListings = (defaultListing:{[key:string]:any}, platformListing:{[key:string]:any}) => {
		const merged = { ...defaultListing };
		for (const key in platformListing) {
			const platformValue = platformListing[key];

			if (
				platformValue !== undefined &&
				platformValue !== null &&
				platformValue !== ''
			) {
				if (
					typeof platformValue === 'object' &&
					!Array.isArray(platformValue) &&
					platformValue !== null
				) {
					// Recursively merge nested objects
					merged[key] = mergeListings(defaultListing[key] || {}, platformValue);
				} else {
					merged[key] = platformValue;
				}
			}
		}
		return merged;
	};

	function mergeListingToInventory(listing: Listing, inventoryId:string): Inventory {
		// Create a copy of the inventory to avoid mutating the original object
		const mergedInventory = { ...initialInventory };
		// Overlapping fields where Listing takes precedence
		mergedInventory.id = inventoryId;
		mergedInventory.user = userStore.getUser()?.id as string;
		mergedInventory.SKU = listing.SKU || initialInventory.SKU;
		mergedInventory.brand = listing.brand || initialInventory.brand;
		mergedInventory.color = listing.color || initialInventory.color;
		mergedInventory.sizes = listing.sizes && listing.sizes.length > 0 ? listing.sizes : initialInventory.sizes;
		mergedInventory.description = listing.description || initialInventory.description;
		// Map Listing.title to Inventory.name
		mergedInventory.name = listing.title || initialInventory.name;
		// Use the first image URL from Listing.imageUrls for Inventory.imageUrl
		mergedInventory.imageUrl = listing.imageUrls && listing.imageUrls.length > 0 ? listing.imageUrls[0] : initialInventory.imageUrl;
		// Map Listing.quantity to Inventory.totalQuantity
		mergedInventory.totalQuantity = listing.quantity || initialInventory.totalQuantity;
		// Map Listing.category (string) to Inventory.category (enum)
		mergedInventory.category = listing.category ? categoriesFromJSON(listing.category) : initialInventory.category;
		// the list of selected platforms
		mergedInventory.platform = selectedPlatforms;
		// Return the merged inventory object
		return mergedInventory;
	}

	const handlePlatformChange = (platform: MarketplacePlatform) => {
		setSelectedPlatforms((prev) => {
			if (prev.includes(platform)) {
				return prev.filter((p) => p !== platform);
			} else {
				// Initialize custom fields for new platform
				if (!customMarketPlaceListing[platform]) {
					setCustomMarketPlaceListing((fields) => ({
						...fields,
						[platform]: { ...customMarketPlaceListing["-1"] }, // Use the general form as default
					}));
				}
				return [...prev, platform];
			}
		});
	};

	const currentPlatformChange = async (platform: number) => {
		// Cache the current listing data
		setCustomMarketPlaceListing((prev) => ({
			...prev,
			[currentPlatform]: {...listing}, // Cache under currentPlatform
		}));
		// Load new platform's listing data
		const defaultListing = currentPlatform === -1 ? listing : (customMarketPlaceListing["-1"] || initialData);
		const platformListing = customMarketPlaceListing[platform] || {};
		// Merge defaultListing with platformListing using the merge function
		const mergedListing = mergeListings(defaultListing, platformListing);
		// Update the listing state
		setListing(mergedListing as Listing);
		await new Promise((resolve) => setTimeout(resolve, 10));
		// Update currentPlatform
		setCurrentPlatform(platform);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		let { name, value } = e.target;
		if(name === "tagsAndKeywords") {
			// @ts-ignore
			value = value.toString().split(", ")
		}
		setListing((prevListing) => ({
			...prevListing,
			[name]: value,
		}));
	};

	const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const files = Array.from(e.target.files);
			const imageUrls = files.map(file => URL.createObjectURL(file));
			setListing((prevListing) => ({
				...prevListing,
				imageUrls,
			}));
		}
	};

	const createInventoryFunction = async (listing:Listing, inventoryId:string):Promise<any> => {
		await createInventory(mergeListingToInventory(listing, inventoryId))
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					inventoryStore.addInventory([res.data])
					return res.data
				} else {
					console.log(res);
					return res
				}
			})
	}

	const createListingFunction = async (listing:Listing, inventoryId: string) => {
		listing.user = userStore.getUser()?.id as string;
		listing.createdAt = new Date().getTime();
		listing.updatedAt = new Date().getTime();
		listing.inventoryId = inventoryId;
		await createListing({...listing, id: generateUUID() })
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					listingsStore.addListings([res.data]);
				} else {
					console.log(res)
				}
			})
	}

	const creationProcess = async():Promise<{[key: string]:any}> => {
		// the inventoryId
		const inventoryId = initialInventory.id || generateUUID();
		const returnData =  {};

		// Loop through platforms and create listings
		const marketPlaceIds = Object.keys(customMarketPlaceListing).filter((id) => id !== '-1');

		// the function to create the inventory object
		return createInventoryFunction(customMarketPlaceListing['-1'], inventoryId)
			.then((res:any) => {
				if (!res.isError) {
					const createListings = [];
					for (const platformId of marketPlaceIds) {
						createListings.push(createListingFunction(customMarketPlaceListing[platformId], inventoryId));
					}
					return Promise.all(createListings);
				}
				return Promise.reject(res);
			})
	}

	const handleFormSubmit = async () => {
		setLoading(true);
		// Cache the current listing data
		setCustomMarketPlaceListing((prev) => ({
			...prev,
			[currentPlatform]: { ...listing },
		}));

		// Wait for state update
		await new Promise((resolve) => setTimeout(resolve, 10));

		try {
			await creationProcess()
				.then((res: { [key: string]: any }) => {
					if (!res.isError) {
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Listing Created`,
							message: `Listing information successfully created`
						});
						setTimeout(() => {
							setShowDialog(false);
							onClose();
						}, 2000);
					} else {
						setLoading(false);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: true,
							title: "Error updating user object",
							message: res.error?.errorMessage || res.message
						});
						setListing((prevListing) => ({
							...prevListing,
							id: "",
						}));
					}
				})
				.catch((err: any) => {
					console.log(err);
					setLoading(false);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating/creating listing object",
						message: err.message
					});
					setListing((prevListing) => ({
						...prevListing,
						id: "",
					}));
				});
		} catch (error) {
			console.error('Error creating listing:', error);
		} finally {
			setLoading(false);
		}
	};

	const handleToggleSizeType = () => {
		setSizeType((prevType) => (prevType === 'US' ? 'EU' : 'US'));
	};

	const handleSizeChange = (index: number, field: string, value: string | number) => {
		const newSizes = [...listing.sizes];
		newSizes[index] = {...newSizes[index], [field]: value};
		setListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: newSizes,
			quantity: calculateTotalQuantity(newSizes),
		}));
		setOpenCategory(null);
		setOpenMenu(false);
	};

	const handleAddSize = () => {
		setListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: [...prevInventory.sizes, {size: '', quantity: 0}],
		}));
	};

	const handleCategoryClick = (category: string) => {
		setOpenCategory(openCategory === category ? null : category);
	};

	const handleRemoveSize = (index: number) => {
		const newSizes = listing.sizes.filter((_: any, i: any) => i !== index);
		setListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: newSizes,
			quantity: calculateTotalQuantity(newSizes),
		}));
	};

	const handleShippingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		// @ts-ignore
		setListing((prevListing) => ({
			...prevListing,
			shipping: {
				...prevListing.shipping,
				[name]: value,
			},
		}));
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		});
	};

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />;
	}

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => {
				setShowDialog(false);
				onClose();
			}}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25"/>
				</Transition.Child>
				<div className="fixed inset-0 overflow-y-auto">
					<Transition.Child
						as={"div"}
						className={"relative"}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Dialog.Panel
							className="transform transition-all">
							<div style={{ display: 'grid', gridTemplateColumns: '50px auto'}} className="grid min-h-full w-fit mx-auto items-center justify-center p-4 text-center">
								<div className="self-start mt-8 sticky z-[100] top-10 w-fit">
									<div
										onClick={(e: any) => {
											e.stopPropagation();
											e.preventDefault();
											currentPlatformChange(-1)
										}}
										className={`border hover:bg-opacity-95 cursor-pointer overflow-hidden px-4 py-2 shadow sm:px-2 flex justify-between ${
											currentPlatform === -1 ? 'bg-deepPurple border-deepPurple text-white' : 'bg-gray-200 dark:text-white dark:border-gray-700 dark:bg-gray-800'
										}`}>
										<div className="flex items-center space-x-2">
											<BuildingStorefrontIcon className="h-8 w-8 rounded-full"/>
										</div>
									</div>
									{Object.entries(marketplaceImages).map(([key, {src, alt}]) => {
										return (
											<div key={key} onClick={!selectedPlatforms.includes(parseInt(key)) ? undefined : (e: any) => {
												currentPlatformChange(parseInt(key))
												e.stopPropagation();
												e.preventDefault();
											}}
										     className={`border ${!selectedPlatforms.includes(parseInt(key)) ? "grayscale" : "cursor-pointer"} hover:bg-opacity-95 overflow-hidden px-4 py-2 shadow sm:px-2 flex justify-between ${
											     currentPlatform === parseInt(key) ? 'bg-deepPurple border-deepPurple text-white' : 'bg-gray-200 dark:text-white dark:border-gray-700 dark:bg-gray-800'
										     }`}>
												<div className="flex items-center space-x-2">
													<img src={src} alt={alt} className="h-8 w-8 rounded-full"/>
												</div>
											</div>
									)})}
								</div>
								<form className={"w-full max-w-2xl overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl"} onSubmit={(e) => {
									e.preventDefault();
									handleFormSubmit().catch((err) => {
										console.log(err);
									});
								}}>
									<Dialog.Title as="div" className="flex justify-between items-center text-2xl font-medium leading-6 text-gray-900 dark:text-white">
										<span>Create Listing</span>
										<button
											onClick={() => {
												setShowDialog(false);
												onClose();
											}}
											className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
										>
											<XMarkIcon className="h-6 w-6" aria-hidden="true"/>
										</button>
									</Dialog.Title>
									{
										currentPlatform === -1 && (
											<div className="mt-4">
												<label htmlFor="platforms" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Platforms <span className={"text-red-500 font-bold"}>*</span>
												</label>
												<Menu as="div" className="relative inline-block text-left w-full">
													<div>
														<Menu.Button
															className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
															Select Platforms {selectedPlatforms.length && " (" + selectedPlatforms.length + " Selected)"}
															<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
														</Menu.Button>
													</div>
													<Transition
														as={React.Fragment}
														enter="transition ease-out duration-100"
														enterFrom="transform opacity-0 scale-95"
														enterTo="transform opacity-100 scale-100"
														leave="transition ease-in duration-75"
														leaveFrom="transform opacity-100 scale-100"
														leaveTo="transform opacity-0 scale-95"
													>
														<Menu.Items
															className="absolute dark:border dark:border-gray-700 left-0 z-[100] w-full origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
															<div className="py-1 max-h-80 overflow-y-auto">
																{Object.keys(MarketplacePlatform)
																	.filter(key => isNaN(Number(key)))
																	.map((key) => (
																		<Menu.Item key={key}>
																			{({active}) => (
																				<div
																					className={`flex items-center px-4 py-2 ${active ? 'bg-gray-100 dark:bg-gray-700' : ''}`}>
																					<input
																						id={key}
																						name={key}
																						type="checkbox"
																						checked={selectedPlatforms.includes(MarketplacePlatform[key as keyof typeof MarketplacePlatform])}
																						onChange={() => handlePlatformChange(MarketplacePlatform[key as keyof typeof MarketplacePlatform])}
																						className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
																					/>
																					<label htmlFor={key}
																					       onChange={() => handlePlatformChange(MarketplacePlatform[key as keyof typeof MarketplacePlatform])}
																					       className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300">
																						{key}
																					</label>
																				</div>
																			)}
																		</Menu.Item>
																	))}
															</div>
														</Menu.Items>
													</Transition>
												</Menu>
											</div>
										)
									}
									<div className="mt-4">
										<label htmlFor="title"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Title <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="title"
												name="title"
												type="text"
												autoComplete="title"
												value={listing.title}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="description"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Description <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
                      <textarea
	                      id="description"
	                      name="description"
	                      autoComplete="description"
	                      value={listing.description}
	                      onChange={handleInputChange}
	                      className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
	                      rows={4}
                      />
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="SKU"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											SKU <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="SKU"
												name="SKU"
												type="text"
												autoComplete="sku"
												value={listing.SKU}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="brand"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Brand <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="brand"
												name="brand"
												type="text"
												autoComplete="brand"
												value={listing.brand}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="color"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Color
										</label>
										<div className="mt-2">
											<input
												id="color"
												name="color"
												type="text"
												autoComplete="color"
												value={listing.color}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="price"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Price <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="price"
												name="price"
												type="number"
												autoComplete="price"
												value={listing.price}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="currencyCode"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Currency Code
										</label>
										<div className="mt-2">
											<select
												id="currencyCode"
												name="currencyCode"
												value={listing.currencyCode}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											>
												<option value="USD">USD ($)</option>
												<option value="EUR">EUR (€)</option>
												<option value="GBP">GBP (£)</option>
											</select>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="quantity"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Quantity
										</label>
										<div className="mt-2">
											<input
												id="quantity"
												name="quantity"
												type="number"
												autoComplete="quantity"
												value={listing.quantity}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="category"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Category <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<select
												id="category"
												name="category"
												value={listing.category}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											>
												<option value="">Select a category</option>
												{Object.values(Categories)
													.filter(value => typeof value === 'number') // Ensure we're only dealing with numeric values
													.map((value) => (
														<option key={value} value={categoriesToJSON(value as Categories)}>
															{categoriesToJSON(value as Categories)}
														</option>
													))}
											</select>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="condition"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Condition
										</label>
										<div className="mt-2">
											<select
												id="condition"
												name="condition"
												value={listing.condition}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											>
												<option value="new">New</option>
												<option value="slightly used">Slightly Used</option>
												<option value="used">Used</option>
											</select>
										</div>
									</div>
									<div className="mt-3 relative">
										<div className="flex w-24 items-center relative justify-between">
											<label htmlFor="sizes"
											       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
												Sizes
											</label>
											<Switch
												checked={sizeType === 'EU'}
												onChange={handleToggleSizeType}
												className={`${sizeType === 'EU' ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
											>
												<span className="sr-only">Toggle Size Type</span>
												<span
													className={`${sizeType === 'EU' ? 'translate-x-6' : 'translate-x-1'} inline-block text-[0.5rem] font-avenir-bold h-4 w-4 pt-[0.15rem] transform rounded-full bg-white transition`}>
														{sizeType}
														</span>
											</Switch>
										</div>
										{
											openMenu && <Transition
												as={"div"}
												enter="transition ease-out duration-100"
												enterFrom="transform opacity-0 scale-95"
												enterTo="transform opacity-100 scale-100"
												leave="transition ease-in duration-75"
												leaveFrom="transform opacity-100 scale-100"
												leaveTo="transform opacity-0 scale-95"
											>
												<div
													className="absolute left-0 bottom-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
													<div className="py-1 max-h-60 overflow-y-auto">
														{Object.keys(productSizes).map((category) => (
															<div key={category}>
																<div>
																	<div
																		onClick={(e: any) => {
																			e.preventDefault();
																			handleCategoryClick(category)
																		}}
																		className={`inline-flex w-full px-4 py-2 text-left cursor-pointer text-sm font-semibold text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}
																	>
																		{category.charAt(0).toUpperCase() + category.slice(1)}
																		<ChevronRightIcon aria-hidden="true"
																		                  className="-mr-1 h-5 w-5 text-gray-400"/>
																	</div>
																</div>
															</div>
														))}
													</div>
												</div>
											</Transition>
										}
										{
											openCategory &&
											<Transition
												as={"div"}
												enter="transition ease-out duration-100"
												enterFrom="transform opacity-0 scale-95"
												enterTo="transform opacity-100 scale-100"
												leave="transition ease-in duration-75"
												leaveFrom="transform opacity-100 scale-100"
												leaveTo="transform opacity-0 scale-95"
											>
												<div
													className="absolute left-[225px] -bottom-2.5 z-20 mt-2 w-56 origin-top-left rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-y-auto">
													<div className="py-1">
														{productSizes[openCategory][sizeType.toLowerCase()].map((sizeOption) => (
															<div key={sizeOption}>
																<button
																	type="button"
																	onClick={(e) => {
																		e.preventDefault();
																		handleSizeChange(currentSizeIndex, 'size', sizeOption)
																	}}
																	className={`block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}
																>
																	{sizeOption}
																</button>
															</div>
														))}
													</div>
												</div>
											</Transition>
										}
										{listing.sizes.map((size, index) => (
											<div key={index} className="flex items-center mt-1">
												<div className="relative inline-block text-left w-1/2 mr-2">
													<div>
														<div
															onClick={() => {
																setCurrentSizeIndex(index)
																setOpenMenu(!openMenu)
															}}
															className="inline-flex w-full cursor-pointer justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
															{size.size || 'Select Size'}
															<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
														</div>
													</div>
												</div>
												<input
													type="number"
													name={`quantity-${index}`}
													value={size.quantity}
													onChange={(e) => {
														e.preventDefault();
														handleSizeChange(index, 'quantity', parseInt(e.target.value))
													}}
													className="mr-2 block w-1/2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													placeholder="Quantity"
												/>
												<button
													type="button"
													onClick={() => handleRemoveSize(index)}
													className={`${index === 0 && "invisible"} text-red-500 hover:text-red-700`}
												>
													<XCircleIcon className="h-6 w-6"/>
												</button>
												<button
													type="button"
													onClick={handleAddSize}
													className="text-blue-500 hover:text-blue-700 ml-2"
												>
													<PlusCircleIcon className="h-6 w-6"/>
												</button>
											</div>
										))}
									</div>
									<div className="mt-4">
										<label htmlFor="seller"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Seller
										</label>
										<div className="mt-2">
											<input
												id="seller"
												name="seller"
												type="text"
												autoComplete="seller"
												value={listing.seller}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="location"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Location
										</label>
										<div className="mt-2">
											<input
												id="location"
												name="location"
												type="text"
												autoComplete="location"
												value={listing.location}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="tagsAndKeywords"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Tags And Keywords
										</label>
										<div className="mt-2">
											<input
												id="tagsAndKeywords"
												name="tagsAndKeywords"
												type="text"
												autoComplete="tags-and-keywords"
												value={listing.tagsAndKeywords.join(", ")}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div>
										<h1 className={"block text-lg pt-4 font-medium leading-6 text-gray-900 dark:text-white"}>Shipping
											Information</h1>
										<div className={"flex mt-4 justify-between space-x-0 md:space-x-4 md:flex-row flex-col"}>
											<div className="md:w-1/2 w-full">
												<label htmlFor="shipping-cost"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Shipping Cost
												</label>
												<div className="mt-2">
													<input
														id="shipping-cost"
														name="cost"
														type="number"
														value={listing.shipping?.cost}
														onChange={handleShippingChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="md:w-1/2 w-full">
												<label htmlFor="estimated-delivery-time"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Estimated Delivery Time (days)
												</label>
												<div className="mt-2">
													<input
														id="estimated-delivery-time"
														name="estimatedDeliveryTime"
														type="number"
														value={listing.shipping?.estimatedDeliveryTime}
														onChange={handleShippingChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
										</div>
										<div className="mt-4">
											<label htmlFor="shipping-method"
											       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
												Shipping Method
											</label>
											<div className="mt-2">
												<input
													id="shipping-method"
													name="method"
													type="text"
													value={listing.shipping?.method}
													onChange={handleShippingChange}
													className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
												/>
											</div>
										</div>
										<div className={"flex mt-4 justify-between space-x-0 md:space-x-4 md:flex-row flex-col"}>
											<div className="md:w-1/2 w-full">
												<label htmlFor="package-weight"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Package Weight
												</label>
												<div className="mt-2">
													<input
														id="package-weight"
														name="packageWeight"
														type="number"
														value={listing.shipping?.packageWeight}
														onChange={handleShippingChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className={"flex justify-between space-x-0 md:space-x-4 md:flex-row flex-col"}>
												<div className="md:w-1/2 w-full">
													<label htmlFor="package-dimension"
													       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
														Package Width
													</label>
													<div className="mt-2">
														<input
															id="package-dimension"
															name="packageDimensionWidth"
															type="number"
															value={listing.shipping?.packageDimensionWidth}
															onChange={handleShippingChange}
															className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
												<div className="md:w-1/2 w-full">
													<label htmlFor="package-dimension"
													       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
														Package Height
													</label>
													<div className="mt-2">
														<input
															id="package-dimension"
															name="packageDimensionHeight"
															type="number"
															value={listing.shipping?.packageDimensionHeight}
															onChange={handleShippingChange}
															className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="scheduleDateTime"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Schedule Date & Time
										</label>
										<div className="mt-2">
											<input
												id="scheduleDateTime"
												name="scheduleDateTime"
												type="datetime-local"
												autoComplete="schedule-date-time"
												value={new Date(listing.scheduleDateTime).toISOString().slice(0, 16)}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4 hidden">
										<label htmlFor="status"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Status
										</label>
										<div className="mt-2">
											<input
												id="status"
												name="status"
												type="text"
												disabled={true}
												autoComplete="status"
												value={listing.status}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-300 dark:bg-white/40 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4 hidden">
										<label htmlFor="listingUrl"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Listing URL
										</label>
										<div className="mt-2">
											<input
												id="listingUrl"
												name="listingUrl"
												type="url"
												disabled={true}
												autoComplete="listing-url"
												value={listing.listingUrl}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-300 dark:bg-white/40 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="col-span-full mt-4">
										<label htmlFor="imageUrls"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Images
										</label>
										<div
											className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-400 dark:border-white/25 px-6 py-10">
											<div className="text-center">
												<PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-500"/>
												<div className="mt-4 flex text-sm leading-6 text-gray-400">
													<label
														htmlFor="file-upload"
														className="relative cursor-pointer rounded-md bg-gray-900 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-500"
													>
														<span className="px-2 py-2 underline !bg-transparent text-deepPurple rounded text-sm">Upload files</span>
														<input
															id="file-upload"
															name="imageUrls"
															type="file"
															accept="image/*"
															multiple
															onChange={handleImageUpload}
															className="sr-only"
														/>
													</label>
													<p className="pl-1">or drag and drop</p>
												</div>
												<p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB each</p>
											</div>
										</div>
									</div>
									<div className="mt-4 flex justify-end">
										<button
											type="submit"
											disabled={loading}
											className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{loading ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
												     fill="none"
												     viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													        strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor"
													      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												'Create Listing'
											)}
										</button>
									</div>
								</form>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
			{notificationComponent}
		</Transition>
	);
};
export default ListingForm;

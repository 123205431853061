import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Collection } from '../utils/types';
import {userStore} from "../state/User";
import {generateUUID} from "../utils/data";
import {NotificationPopupComponent} from "./NotificationPopup";
import {createCollection, updateCollection} from "../api/collections";
import {collectionsStore} from "../state/Collection";

const initialCollection: Collection = {
	id: '',
	user: '',
	name: '',
	listed: false,
	autoList: false,
	scheduleListing: 0,
	createdAt: Date.now(),
	updatedAt: Date.now(),
};

const CollectionForm = ({ initialData = initialCollection, onClose }: { onClose: () => void; initialData?: Collection }) => {
	const [collection, setCollection] = useState(initialData);
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(true);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setCollection((prevCollection) => ({
			...prevCollection,
			[name]: value,
		}));
	};

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = e.target;
		setCollection((prevCollection) => ({
			...prevCollection,
			[name]: checked,
		}));
	};

	const handleFormSubmit = async () => {
		setLoading(true);
		try {
			collection.user = userStore.getUser()?.id as string
			let update: boolean = false;
			if(collection.id) {
				collection.updatedAt = new Date().getTime()
				update = true;
			} else {
				collection.createdAt = new Date().getTime()
				collection.updatedAt = new Date().getTime()
				collection.id = generateUUID();
			}
			const func = update ? updateCollection.bind(this, collection.id) : createCollection
			await func(collection)
				.then((res: { [key: string]: any }) => {
					if (!res.isError) {
						if (update) {
							collectionsStore.updateCollection(res.data)
						} else {
							collectionsStore.addCollections([res.data])
						}
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Collection ${update ? "Updated" : "Created"}`,
							message: `Collection information successfully ${update ? "updated" : "created"}`
						})
						setTimeout(() => {
							setShowDialog(false);
							onClose();
						}, 2e3);
					} else {
						setLoading(false);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: true,
							title: "Error updating user object",
							// @ts-ignore
							message: res.error?.errorMessage | res.message
						})
					}
				})
				.catch((err: any) => {
					console.log(err);
					setLoading(false);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating/creating collection object",
						message: err.message
					})
				})
		} catch (error) {
			console.error('Error creating collection:', error);
		} finally {
			setLoading(false);
		}
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	}
	let notificationComponent;
	if(notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />
	}

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => { setShowDialog(false); onClose(); }}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
								<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
									Create Collection
								</Dialog.Title>
								<form onSubmit={(e) => {
									e.preventDefault();
									handleFormSubmit();
								}}>
									<div className="mt-4">
										<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Name <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="name"
												name="name"
												type="text"
												autoComplete="name"
												value={collection.name}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="scheduleListing"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Schedule Listing (Timestamp)
										</label>
										<div className="mt-2">
											<input
												id="scheduleListing"
												name="scheduleListing"
												type="datetime-local"
												autoComplete="schedule-listing"
												value={collection.scheduleListing}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4 flex items-center">
										<input
											id="autoList"
											name="autoList"
											type="checkbox"
											checked={collection.autoList}
											onChange={handleCheckboxChange}
											className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:text-white"
										/>
										<label htmlFor="autoList"
										       className="ml-2 block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Auto List
										</label>
									</div>
									<div className="mt-4 flex justify-end">
										<button
											type="submit"
											disabled={loading}
											className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{loading ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
												     viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													        strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor"
													      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												'Create Collection'
											)}
										</button>
									</div>
								</form>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
			{ notificationComponent }
		</Transition>
	);
};

export default CollectionForm;

import React from "react";
import { MarketplacePlatform } from '../utils/types';
import amazonIcon from "../images/Amazon.svg";
import ebayIcon from "../images/eBay.svg";
import mercariIcon from "../images/Mercari.svg";
import grailedIcon from "../images/Grailed.svg";
import etsyIcon from "../images/Etsy.svg";
import facebookIcon from "../images/FacebookMarketplaces.svg";
import shopifyIcon from "../images/Shopify.svg";
import lightSpeedIcon from "../images/lightspeed.svg";
import stockXIcon from "../images/stockx.svg";
import goatIcon from "../images/goat-rounded.svg";
import depopIcon from "../images/Depop.svg";
import woocommerceIcon from "../images/woocommerce-icon-svgrepo-com.svg";

export const marketplaceImages: { [key: number]: any } = {
	[MarketplacePlatform.EBAY]: {
		src: ebayIcon,
		alt: 'eBay',
	},
	[MarketplacePlatform.GRAILED]: {
		src: grailedIcon,
		alt: 'Grailed',
	},
	[MarketplacePlatform.MERCARI]: {
		src: mercariIcon,
		alt: 'Mercari',
	},
	[MarketplacePlatform.SHOPIFY]: {
		src: shopifyIcon,
		alt: 'Shopify',
	},
	[MarketplacePlatform.STOCKX]: {
		src: stockXIcon,
		alt: 'StockX',
	},
	[MarketplacePlatform.LIGHTSPEED]: {
		src: lightSpeedIcon,
		alt: 'Lightspeed',
	},
	[MarketplacePlatform.GOAT]: {
		src: goatIcon,
		alt: 'GOAT',
	},
	[MarketplacePlatform.AMAZON]: {
		src: amazonIcon,
		alt: 'Amazon',
	},
	[MarketplacePlatform.ETSY]: {
		src: etsyIcon,
		alt: 'Etsy',
	},
	[MarketplacePlatform.FACEBOOK]: {
		src: facebookIcon,
		alt: 'Facebook',
	},
	[MarketplacePlatform.DEPOP]: {
		src: depopIcon,
		alt: 'Depop',
	},
	[MarketplacePlatform.WOOCOMMERCE]: {
		src: woocommerceIcon,
		alt: 'WooCommerce',
	},
};

interface ListingImageCloudProps {
	marketplaces: MarketplacePlatform[];
}

export default function ListingImageCloud({ marketplaces }: ListingImageCloudProps) {
	return (
		<div className="isolate flex -space-x-2 overflow-hidden">
			{marketplaces.map((platform) => {
				const image = marketplaceImages[platform];
				return image ? (
					<img key={platform} src={image.src} alt={image.alt} className="h-6 w-6 rounded-full" />
				) : null;
			})}
		</div>
	);
}

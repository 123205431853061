import React, {useEffect, useState} from "react";
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {TrashIcon, XMarkIcon, ClipboardIcon} from '@heroicons/react/24/outline';
import {userStore} from "../state/User";
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import {loadStripe, PaymentMethod} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {Dialog, Transition} from '@headlessui/react';
import userImage from "../images/settings-icon-account.png";
import invoiceImage from "../images/order-pending.png";
import {getUserInvoices, updatePlan, updateUser, updateUserAddons} from "../api/user";
import {Plan, User} from "app-ts-types/users";
import {NotificationPopupComponent} from "../components/NotificationPopup";
import {Field, Label, Switch} from '@headlessui/react'
import {Invoice} from "../utils/types";

const stripePromise = loadStripe('pk_test_51PpluwRwi8cbrWgqI7zPnes08sLlXr01eukFqn9T9cshd2oADZe0XfWNNK5vG7Wdr9oFzmXGUHlshr43wKe3plPt00n1ThnBEx');

const tiers = [
	{
		name: 'Starter',
		id: 'prod_R1lbsh9TUUfbqI',
		href: '/signup?plan=Starter',
		priceMonthly: 'Free',
		description: 'Limited version with basic features.',
		mostPopular: false,
	},
	{
		name: 'Basic',
		id: 'prod_R1lbIQW6dxlFlh',
		href: '/signup?plan=Basic',
		priceMonthly: 29.99, // Monthly price in dollars
		description: 'Core features with expanded capabilities.',
		mostPopular: false,
	},
	{
		name: 'Premium',
		id: 'prod_R1lbQ5beG2z5X5',
		href: '/signup?plan=Premium',
		priceMonthly: 69.99, // Monthly price in dollars
		description: 'Full access to advanced features and integrations.',
		mostPopular: true,
	},
	{
		name: 'Enterprise',
		id: 'prod_R1lbXahY7wvdwE',
		href: '/signup?plan=Enterprise',
		priceMonthly: 'Contact Us',
		description: 'Customized solutions with unlimited features.',
		mostPopular: false,
	}
];
const addonMapping: {[key:string]: string[]} = {
	prod_R1lH44l9r1Rpxk: ['Enterprise', 'Premium'], // AI Integration
	prod_R1lHpmgez2tGhi: ['Enterprise', 'Premium'], // Import Inventory Items
	prod_R1lHgjHqsP5wBK: ['Enterprise', 'Premium'], // Email Tracking
	prod_R1lHCpQz5gpUUJ: ['Enterprise'], // QuickBooks Integration
	prod_R1lHazmXnU7W31: ['Enterprise'], // Xero Integration
	prod_R1lH9FycJhrfbl: ['Enterprise'], // Intuit Online Payroll Integration
	prod_R1lHDYKI6DtZbC: ['Enterprise'], // API Access
	prod_R1lH0dhvNQGTF3: ['Enterprise'], // Priority Support
	prod_R1lHtiJ7DNmDYj: ['Enterprise'], // Team Support
	prod_R1lHD0h3AVzoeM: ['Enterprise'], // Priority Support Bundle
	prod_R1lHU3H7ToeWI0: ['Enterprise', 'Premium'], // AI & Inventory Bundle
	prod_R1lHZqVNAMRzcD: ['Enterprise', 'Premium'], // eBay Integration
	prod_R1lHPGAHD7rhUv: ['Enterprise', 'Premium'], // Grailed Integration
	prod_R1lHMsaWBQwHP0: ['Enterprise', 'Premium'], // Mercari Integration
	prod_R1lH7sOIttcZgn: ['Enterprise', 'Premium'], // Shopify Integration
	prod_R1lHfa9vE821Nz: ['Enterprise', 'Premium'], // StockX Integration
	prod_R1lHYecdOquCWj: ['Enterprise', 'Premium'], // Lightspeed Integration
	prod_R1lHGUmnLrTdFd: ['Enterprise', 'Premium'], // GOAT Integration
	prod_R1lIKuLJtxLnir: ['Enterprise', 'Premium'], // Amazon Integration
	prod_R1lISKAuhQYO9L: ['Enterprise', 'Premium'], // Etsy Integration
	prod_R1lIfLUld7AXjA: ['Enterprise', 'Premium'], // Depop Integration
	prod_R1lI1CVQAnptx8: ['Enterprise', 'Premium'], // Facebook Marketplace Integration
	prod_R1lIQlTuitNXKr: ['Enterprise', 'Premium']  // WooCommerce Integration
};
const addons = [
	{
		name: 'AI Integration',
		id: 'prod_R1lH44l9r1Rpxk',
		price: 4.99,
		description: 'Leverage AI-powered features for automated tasks.',
	},
	{
		name: 'Import Inventory Items',
		id: 'prod_R1lHpmgez2tGhi',
		price: 3.99,
		description: 'Easily import your existing inventory from popular marketplaces.',
	},
	{
		name: 'Email Tracking',
		id: 'prod_R1lHgjHqsP5wBK',
		price: 14.99,
		description: 'Track email communications, inventory updates, and marketplace notifications in one place.',
	},
	{
		name: 'QuickBooks Integration',
		id: 'prod_R1lHCpQz5gpUUJ',
		price: 9.99,
		description: 'Integrate TrackNList with QuickBooks for seamless financial management.',
	},
	{
		name: 'Xero Integration',
		id: 'prod_R1lHazmXnU7W31',
		price: 9.99,
		description: 'Integrate TrackNList with Xero for seamless financial management.',
	},
	{
		name: 'Intuit Online Payroll Integration',
		id: 'prod_R1lH9FycJhrfbl',
		price: 9.99,
		description: 'Integrate TrackNList with Intuit Online Payroll for seamless financial management.',
	},
	{
		name: 'API Access',
		id: 'prod_R1lHDYKI6DtZbC',
		price: 14.99,
		description: "Gain full control over TrackNList's functionality through our API.",
	},
	{
		name: 'Priority Support',
		id: 'prod_R1lH0dhvNQGTF3',
		price: 9.99,
		description: 'Receive expedited support for urgent issues.',
	},
	{
		name: 'Team Support',
		id: 'prod_R1lHtiJ7DNmDYj',
		price: 9.99,
		description: 'Add team members to your account and manage their permissions.',
	},
	{
		name: 'AI & Inventory Bundle',
		id: 'prod_R1lHU3H7ToeWI0',
		price: 8.48,
		description: 'Get AI Integration and Import Inventory Items at a discounted rate.',
	},
	{
		name: 'Priority Support Bundle',
		id: 'prod_R1lHD0h3AVzoeM',
		price: 18.98,
		description: 'Get Priority Support and Team Support at a discounted rate.',
	},
	{
		name: 'eBay Integration',
		id: 'prod_R1lHZqVNAMRzcD',
		price: 4.99,
		description: 'Integrate with eBay for seamless cross-listing and inventory management.',
	},
	{
		name: 'Grailed Integration',
		id: 'prod_R1lHPGAHD7rhUv',
		price: 4.99,
		description: 'Integrate with Grailed for seamless cross-listing and inventory management.',
	},
	{
		name: 'Mercari Integration',
		id: 'prod_R1lHMsaWBQwHP0',
		price: 4.99,
		description: 'Integrate with Mercari for seamless cross-listing and inventory management.',
	},
	{
		name: 'Shopify Integration',
		id: 'prod_R1lH7sOIttcZgn',
		price: 4.99,
		description: 'Integrate with Shopify for seamless cross-listing and inventory management.',
	},
	{
		name: 'StockX Integration',
		id: 'prod_R1lHfa9vE821Nz',
		price: 4.99,
		description: 'Integrate with StockX for seamless cross-listing and inventory management.',
	},
	{
		name: 'Lightspeed Integration',
		id: 'prod_R1lHYecdOquCWj',
		price: 4.99,
		description: 'Integrate with Lightspeed for seamless cross-listing and inventory management.',
	},
	{
		name: 'GOAT Integration',
		id: 'prod_R1lHGUmnLrTdFd',
		price: 4.99,
		description: 'Integrate with GOAT for seamless cross-listing and inventory management.',
	},
	{
		name: 'Amazon Integration',
		id: 'prod_R1lIKuLJtxLnir',
		price: 4.99,
		description: 'Integrate with Amazon for seamless cross-listing and inventory management.',
	},
	{
		name: 'Etsy Integration',
		id: 'prod_R1lISKAuhQYO9L',
		price: 4.99,
		description: 'Integrate with Etsy for seamless cross-listing and inventory management.',
	},
	{
		name: 'Depop Integration',
		id: 'prod_R1lIfLUld7AXjA',
		price: 4.99,
		description: 'Integrate with Depop for seamless cross-listing and inventory management.',
	},
	{
		name: 'Facebook Marketplace Integration',
		id: 'prod_R1lI1CVQAnptx8',
		price: 4.99,
		description: 'Integrate with Facebook Marketplace for seamless cross-listing and inventory management.',
	},
	{
		name: 'WooCommerce Integration',
		id: 'prod_R1lIQlTuitNXKr',
		price: 4.99,
		description: 'Integrate with WooCommerce for seamless cross-listing and inventory management.',
	}
];
const sections = [
	{
		name: 'Features',
		features: [
			{
				name: 'Cross Listing',
				tiers: {
					Starter: 'Up to 2 platforms',
					Basic: 'Up to 5 platforms',
					Premium: 'Unlimited platforms',
					Enterprise: 'Unlimited platforms'
				}
			},
			{
				name: 'Supported Platforms',
				tiers: {
					Starter: '2 platforms',
					Basic: '5 platforms',
					Premium: 'Unlimited platforms',
					Enterprise: 'Unlimited platforms'
				}
			},
			{
				name: 'Email/Inventory Tracking',
				tiers: {Starter: false, Basic: 'Inventory updates only', Premium: 'Full access', Enterprise: 'Full access'}
			},
			{
				name: 'In-App Messages',
				tiers: {
					Starter: 'Not available',
					Basic: 'Messages from platforms',
					Premium: 'Messages, Notifications & Alerts',
					Enterprise: 'Messages, Notifications & Alerts'
				}
			},
			{name: 'Inventory Management Tools', tiers: {Starter: true, Basic: true, Premium: true, Enterprise: true}},
			{name: 'AI Insights', tiers: {Starter: 'Add-on', Basic: 'Add-on', Premium: true, Enterprise: true}},
			{
				name: 'Export Formats',
				tiers: {
					Starter: 'Basic (.txt, .csv)',
					Basic: 'Basic (.txt, .csv, .xlsx)',
					Premium: 'All formats',
					Enterprise: 'All formats'
				}
			},
			{
				name: 'Scheduled and Auto Listing Support',
				tiers: {Starter: false, Basic: true, Premium: true, Enterprise: true}
			},
			{
				name: 'Team Support',
				tiers: {Starter: 'Add-on', Basic: 'Add-on', Premium: 'Add-on', Enterprise: 'Included'}
			},
			{
				name: 'Total Inventory Items',
				tiers: {Starter: 'Unlimited', Basic: 'Unlimited', Premium: 'Unlimited', Enterprise: 'Unlimited'}
			},
			{
				name: 'Auto Sale Detection',
				tiers: {
					Starter: '100 items/month',
					Basic: '1000 items/month',
					Premium: 'All listed items',
					Enterprise: 'All listed items'
				}
			},
			{
				name: 'Bulk Listing',
				tiers: {Starter: '10 items', Basic: '250 items', Premium: 'Unlimited items', Enterprise: 'Unlimited Items'}
			},
			{
				name: 'Total listings per Month',
				tiers: {Starter: '100 items', Basic: '1000 items', Premium: '5000 items', Enterprise: 'Unlimited Items'}
			},
			{
				name: 'Custom Titles/Templates per Marketplace',
				tiers: {Starter: false, Basic: true, Premium: true, Enterprise: true}
			},
			{name: 'Draft Listing', tiers: {Starter: true, Basic: true, Premium: true, Enterprise: true}},
			{
				name: 'Background Removal',
				tiers: {Starter: '100 items/month', Basic: '1000 items/month', Premium: 'Unlimited', Enterprise: 'Unlimited'}
			},
			{
				name: 'High Resolution image upload per listing',
				tiers: {
					Starter: '5 images/listing',
					Basic: '15 images/listing',
					Premium: '25 images/listing',
					Enterprise: '25 images/listing'
				}
			},
			{
				name: 'Import Inventory from marketplace listings',
				tiers: {Starter: 'Add-on', Basic: 'Add-on', Premium: 'Included', Enterprise: 'Included'}
			},
			{name: 'API Access', tiers: {Starter: 'Add-on', Basic: 'Add-on', Premium: 'Add-on', Enterprise: 'Included'}},
			{
				name: 'Accounting Software Integration',
				tiers: {Starter: 'Add-on', Basic: 'Add-on', Premium: 'Add-on', Enterprise: 'Included'}
			},
		]
	},
	{
		name: 'Support',
		features: [
			{
				name: 'Customer Service',
				tiers: {Starter: 'Discord Chat', Basic: 'Limited help', Premium: 'Timely help', Enterprise: 'Dedicated help'}
			},
			{
				name: 'Priority Support',
				tiers: {Starter: 'Add-on', Basic: 'Add-on', Premium: 'Add-on', Enterprise: 'Included (24/7)'}
			},
			{
				name: 'Custom Features',
				tiers: {Starter: false, Basic: false, Premium: false, Enterprise: 'Contact us for custom requests'}
			},
		],
	},
	{
		name: 'Add-Ons',
		features: [
			{
				name: 'Additional Platforms',
				tiers: {
					Starter: '$4.99 per platform/month',
					Basic: '$4.99 per platform/month',
					Premium: 'Included',
					Enterprise: 'Included'
				}
			},
			{
				name: 'Priority Support',
				tiers: {Starter: '$9.99 / month', Basic: '$9.99 / month', Premium: '$9.99 / month', Enterprise: 'Included'}
			},
			{
				name: 'Team Support',
				tiers: {
					Starter: '$9.99/month per additional member',
					Basic: '$9.99/month per additional member',
					Premium: '$9.99/month per additional member',
					Enterprise: 'Included'
				}
			},
			{
				name: 'AI Insights',
				tiers: {Starter: '$4.99 / month', Basic: '$4.99 / month', Premium: 'Included', Enterprise: 'Included'}
			},
			{
				name: 'Marketplace Inventory Import',
				tiers: {Starter: '$3.99 / month', Basic: '$3.99 / month', Premium: 'Included', Enterprise: 'Included'}
			},
			{
				name: 'Email Tracking',
				tiers: {
					Starter: '$14.99 / month',
					Basic: '$14.99 / month',
					Premium: 'Full tracking',
					Enterprise: 'Full tracking'
				}
			},
			{
				name: 'API Access',
				tiers: {Starter: '$14.99 / month', Basic: '$14.99 / month', Premium: '$14.99 / month', Enterprise: 'Included'}
			},
			{
				name: 'Accounting Software Integration',
				tiers: {Starter: '$9.99 / month', Basic: '$9.99 / month', Premium: '$9.99 / month', Enterprise: 'Included'}
			}
		],
	},
];
const secondaryNavigation = [
	{name: 'Account', href: '/profile'},
	{name: 'Billing', href: '/profile/billing'},
	{name: 'Plans & Addons', href: '/profile/plan'},
	{name: 'Teams', href: '/profile/teams'},
];
const addonPriceIdMapping:{[key:string]: {[key:string]:string}} = {
	'prod_R1lH44l9r1Rpxk': {
		'month': 'price_1Q9hkkRwi8cbrWgqka240cuF',
		'year': 'price_1Q9hklRwi8cbrWgqS7O6GHjJ'
	},
	'prod_R1lHpmgez2tGhi': {
		'month': 'price_1Q9hknRwi8cbrWgq7LqcPL4f',
		'year': 'price_1Q9hkoRwi8cbrWgqLBWlgqGm'
	},
	'prod_R1lHgjHqsP5wBK': {
		'month': 'price_1Q9hkpRwi8cbrWgqAJLcdT18',
		'year': 'price_1Q9hkqRwi8cbrWgqAMOOQXbp'
	},
	'prod_R1lHCpQz5gpUUJ': {
		'month': 'price_1Q9hkrRwi8cbrWgquFm8KWRX',
		'year': 'price_1Q9hksRwi8cbrWgq0wKFGkTw'
	},
	'prod_R1lHazmXnU7W31': {
		'month': 'price_1Q9hkvRwi8cbrWgqZUXXSJBW',
		'year': 'price_1Q9hkvRwi8cbrWgqZe4EnqCT'
	},
	'prod_R1lH9FycJhrfbl': {
		'month': 'price_1Q9hkxRwi8cbrWgqVQPUScpO',
		'year': 'price_1Q9hkyRwi8cbrWgqCEVaaPmx'
	},
	'prod_R1lHDYKI6DtZbC': {
		'month': 'price_1Q9hl0Rwi8cbrWgqPWHKmvhx',
		'year': 'price_1Q9hl0Rwi8cbrWgqG95Vtjiw'
	},
	'prod_R1lH0dhvNQGTF3': {
		'month': 'price_1Q9hl2Rwi8cbrWgqjOYky3nQ',
		'year': 'price_1Q9hl3Rwi8cbrWgqDlfwVEQO'
	},
	'prod_R1lHtiJ7DNmDYj': {
		'month': 'price_1Q9hl5Rwi8cbrWgqsWQOirFw',
		'year': 'price_1Q9hl6Rwi8cbrWgqMF1lolcQ'
	},
	'prod_R1lHU3H7ToeWI0': {
		'month': 'price_1Q9hl7Rwi8cbrWgqVLNAO01w',
		'year': 'price_1Q9hl8Rwi8cbrWgqcI5J11ZL'
	},
	'prod_R1lHD0h3AVzoeM': {
		'month': 'price_1Q9hlARwi8cbrWgqWzc1LpJ7',
		'year': 'price_1Q9hlARwi8cbrWgqWYBu9Yfa'
	},
	'prod_R1lHZqVNAMRzcD': {
		'month': 'price_1Q9hlCRwi8cbrWgq5nBaIthZ',
		'year': 'price_1Q9hlDRwi8cbrWgqMiyl5DFm'
	},
	'prod_R1lHPGAHD7rhUv': {
		'month': 'price_1Q9hlFRwi8cbrWgqm54kNzZv',
		'year': 'price_1Q9hlFRwi8cbrWgqkVYs8swl'
	},
	'prod_R1lHMsaWBQwHP0': {
		'month': 'price_1Q9hlHRwi8cbrWgqoOplAqsK',
		'year': 'price_1Q9hlHRwi8cbrWgqKx1BE3sC'
	},
	'prod_R1lH7sOIttcZgn': {
		'month': 'price_1Q9hlJRwi8cbrWgqK8TCt1z2',
		'year': 'price_1Q9hlKRwi8cbrWgqAEPel9Gx'
	},
	'prod_R1lHfa9vE821Nz': {
		'month': 'price_1Q9hlLRwi8cbrWgqHFx5angg',
		'year': 'price_1Q9hlMRwi8cbrWgq4DZ2tm29'
	},
	'prod_R1lHYecdOquCWj': {
		'month': 'price_1Q9hlNRwi8cbrWgquCjCqhio',
		'year': 'price_1Q9hlORwi8cbrWgqsSUVsENL'
	},
	'prod_R1lHGUmnLrTdFd': {
		'month': 'price_1Q9hlPRwi8cbrWgqEk7Xn39X',
		'year': 'price_1Q9hlQRwi8cbrWgqxI87icIU'
	},
	'prod_R1lIKuLJtxLnir': {
		'month': 'price_1Q9hlSRwi8cbrWgqLyv8mcJY',
		'year': 'price_1Q9hlTRwi8cbrWgqFj1fZPEv'
	},
	'prod_R1lISKAuhQYO9L': {
		'month': 'price_1Q9hlURwi8cbrWgqvpWPQZp5',
		'year': 'price_1Q9hlVRwi8cbrWgqFsejnem6'
	},
	'prod_R1lIfLUld7AXjA': {
		'month': 'price_1Q9hlWRwi8cbrWgq3RIviRLn',
		'year': 'price_1Q9hlXRwi8cbrWgqQu7cChSB'
	},
	'prod_R1lI1CVQAnptx8': {
		'month': 'price_1Q9hlYRwi8cbrWgqvopgheRr',
		'year': 'price_1Q9hlZRwi8cbrWgqz2PseKgR'
	},
	'prod_R1lIQlTuitNXKr': {
		'month': 'price_1Q9hlbRwi8cbrWgqeXuQmUVt',
		'year': 'price_1Q9hlbRwi8cbrWgq5ZYdabYq'
	}
}
const priceIdMapping:{[key:string]: {[key:string]:string}} = {
	'Starter': {
		'month': 'price_1Q9i4CRwi8cbrWgq2SkImsFF',
		'year': 'price_1Q9i4DRwi8cbrWgqhsDqMUIk'
	},
	'Basic': {
		'month': 'price_1Q9i4FRwi8cbrWgq1HzkR61o',
		'year': 'price_1Q9i4GRwi8cbrWgq4P2ruFB1'
	},
	'Premium': {
		'month': 'price_1Q9i4HRwi8cbrWgq1dY3KNvA',
		'year': 'price_1Q9i4IRwi8cbrWgq3dMlCoXx'
	},
	'Enterprise': {
		'month': 'price_1Q9i4KRwi8cbrWgqg9xdtGQZ',
		'year': 'price_1Q9i4LRwi8cbrWgqgUXZO6yE'
	}
};
const getPriceId = (plan:string, interval:string) => {
	return priceIdMapping[plan]?.[interval] || "price_1Q9i4CRwi8cbrWgq2SkImsFF";
};
// Function to get the price ID based on product ID and interval
function getAddonPriceId(productId:string, interval:string) {
	return addonPriceIdMapping[productId]?.[interval] || null;
}
const teams: any[] = [];

export const CheckoutForm = ({paymentCallback}:{paymentCallback: (error: any, paymentMethod: PaymentMethod | undefined) => Promise<void>; }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		if (!stripe || !elements) {
			return;
		}
		const cardElement = elements.getElement(CardElement);
		if (cardElement) {
			const {error, paymentMethod} = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
			});
			await paymentCallback(error, paymentMethod)
		}
		setLoading(false);
	};

	return (
		<form onSubmit={handleSubmit} className="bg-gray-100 dark:bg-gray-800 p-4 rounded-b-md shadow-sm">
			<h3 className="text-lg font-semibold text-gray-900 dark:text-white">Update Payment Method</h3>
			<div className="mt-4 border rounded p-4">
				<CardElement
					options={{
						style: {
							base: {
								color: 'gray',
								fontFamily: 'Arial, sans-serif',
								fontSmoothing: 'antialiased',
								fontSize: '16px',
								'::placeholder': {
									color: '#aab7c4',
								},
							},
							invalid: {
								color: '#fa755a',
								iconColor: '#fa755a',
							},
						},
					}}
				/>
			</div>
			<button
				type="submit"
				disabled={!stripe}
				className="mt-4 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
			>
				{loading ? (
					<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
					     viewBox="0 0 24 24">
						<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
						<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
					</svg>
				) : (
					'Save Payment Method'
				)}
			</button>
		</form>
	);
};

export const AccountComponent = () => {
	const [loading, setLoading] = useState({
		personalInfo: false,
		changePassword: false,
		logoutOtherSessions: false,
		deleteAccount: false,
		billingInfo: false,
		addingAddon: false,
		planUpdate: false,
		paymentInformation: false,
		teamInvite: false
	});
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	const handlePersonalInfoSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, personalInfo: true}));
		const formData = new FormData(event.currentTarget);
		const data = Object.fromEntries(formData.entries());
		// Call your API function here
		await updateUser(userStore.user!.id, {...userStore.getUser(), ...data} as User)
			.then((res: any) => {
				if (!res.isError) {
					// @ts-ignore
					window.localStorage.setItem('user', JSON.stringify({...userStore.getUser(), ...data}))
					userStore.setUser({...userStore.getUser(), ...data} as User)
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: false,
						title: "User updated",
						message: "User information successfully updated"
					})
				} else {
					// @ts-ignore
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating user object",
						// @ts-ignore
						message: res.error?.errorMessage | res.message
					})
				}
			})
			.catch((err: any) => {
				console.log(err);
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Error updating user object",
					message: err.message
				})
			})
		// Call your API function here
		setLoading((prev) => ({...prev, personalInfo: false}));
	};

	const handleChangePasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, changePassword: true}));
		const formData = new FormData(event.currentTarget);
		const data = Object.fromEntries(formData.entries());
		console.log(data);
		// Call your API function here
		await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate API call
		setLoading((prev) => ({...prev, changePassword: false}));
	};

	const handleLogoutOtherSessionsSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, logoutOtherSessions: true}));
		const formData = new FormData(event.currentTarget);
		const data = Object.fromEntries(formData.entries());
		console.log(data);
		// Call your API function here
		await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate API call
		setLoading((prev) => ({...prev, logoutOtherSessions: false}));
	};

	const handleDeleteAccountSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, deleteAccount: true}));
		// Call your API function here
		await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate API call
		setLoading((prev) => ({...prev, deleteAccount: false}));
	};

	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text).then(
			() => {
				console.log('Text copied to clipboard');
			},
			(err) => {
				console.error('Failed to copy text: ', err);
			}
		);
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	}

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>
	}

	return (
		<div className="divide-y divide-gray-200 dark:divide-white/5">
			{/* Personal Information */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Personal Information</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
						Use a permanent address where you can receive mail.
					</p>
				</div>

				<form className="md:col-span-2" onSubmit={handlePersonalInfoSubmit}>
					<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
						<div className="sm:col-span-3">
							<label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								First name
							</label>
							<div className="mt-2">
								<input
									id="firstName"
									name="firstName"
									type="text"
									defaultValue={userStore.user?.firstName}
									autoComplete="first-name"
									className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="sm:col-span-3">
							<label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								Last name
							</label>
							<div className="mt-2">
								<input
									id="lastName"
									name="lastName"
									type="text"
									defaultValue={userStore.user?.lastName}
									autoComplete="last-name"
									className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="col-span-full">
							<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								Email address
							</label>
							<div className="mt-2">
								<input
									id="email"
									name="email"
									type="email"
									defaultValue={userStore.user?.email}
									autoComplete="email"
									className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="col-span-full">
							<label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								Username
							</label>
							<div className="mt-2">
								<div
									className="flex rounded-md bg-gray-100 dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
									<input
										id="username"
										name="username"
										type="text"
										defaultValue={userStore.user?.username}
										placeholder="janesmith"
										autoComplete="username"
										className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 dark:text-white focus:ring-0 sm:text-sm sm:leading-6"
									/>
								</div>
							</div>
						</div>

						<div className="col-span-full">
							<label htmlFor="discordChannelUrl"
							       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								Discord Channel Webhook
							</label>
							<div className="mt-2">
								<div
									className="flex rounded-md bg-gray-100 dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
									<input
										id="discordChannelUrl"
										name="discordChannelUrl"
										type="text"
										defaultValue={userStore.user?.discordChannelUrl}
										placeholder="Discord Channel Webhook"
										autoComplete="discordChannelUrl"
										className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 dark:text-white focus:ring-0 sm:text-sm sm:leading-6"
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-8 flex">
						<button
							type="submit"
							className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
							disabled={loading.personalInfo}
						>
							{loading.personalInfo ? (
								<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
								     viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path className="opacity-75" fill="currentColor"
									      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
								</svg>
							) : (
								'Save'
							)}
						</button>
					</div>
				</form>
			</div>

			{/* Change Password */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Change password</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
						Update your password associated with your account.
					</p>
				</div>

				<form className="md:col-span-2" onSubmit={handleChangePasswordSubmit}>
					<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
						<div className="col-span-full">
							<label htmlFor="current-password"
							       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								Current password
							</label>
							<div className="mt-2">
								<input
									id="current-password"
									name="current_password"
									type="password"
									autoComplete="current-password"
									className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="col-span-full">
							<label htmlFor="new-password"
							       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								New password
							</label>
							<div className="mt-2">
								<input
									id="new-password"
									name="new_password"
									type="password"
									autoComplete="new-password"
									className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="col-span-full">
							<label htmlFor="confirm-password"
							       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								Confirm password
							</label>
							<div className="mt-2">
								<input
									id="confirm-password"
									name="confirm_password"
									type="password"
									autoComplete="new-password"
									className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
					</div>

					<div className="mt-8 flex">
						<button
							type="submit"
							className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
							disabled={loading.changePassword}
						>
							{loading.changePassword ? (
								<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
								     viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path className="opacity-75" fill="currentColor"
									      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
								</svg>
							) : (
								'Save'
							)}
						</button>
					</div>
				</form>
			</div>

			{/* User ID */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">User ID</h2>
				</div>
				<div>
					<p className="mt-1 text-sm leading-6 flex space-x-2 font-semibold uppercase text-gray-600 dark:text-gray-400">
						{/*@ts-ignore*/}
						<span>{userStore.user!.id || userStore.user!.user_id}</span>
						{/*@ts-ignore*/}
						<span onClick={() => copyToClipboard(userStore.user!.id || userStore.user!.user_id)}>
							<ClipboardIcon className={`h-6 w-6 cursor-pointer`}/>
						</span>
					</p>
				</div>
			</div>

			{/* Connect 3rd Party Accounts */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Connect 3rd party
						Accounts</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
						Connect your marketplace accounts for email tracking and inventory listing.
					</p>
				</div>

				<div className="md:col-span-2">
					<div className="grid grid-cols-4 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
						<div className="col-span-1">
							<a href="https://api.tracknlist.com/api/google/login" className="block w-full">
								<svg viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6 hover:opacity-80">
									<path
										d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
										fill="#EA4335"></path>
									<path
										d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
										fill="#4285F4"></path>
									<path
										d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
										fill="#FBBC05"></path>
									<path
										d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
										fill="#34A853"></path>
								</svg>
							</a>
						</div>
						<div className="col-span-1">
							<a href="https://api.tracknlist.com/api/ebay/login" className="block w-full">
								<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 border p-0.5 rounded hover:opacity-80"
								     viewBox="0 0 24 24"
								     id="ebay">
									<path fill="#E53238"
									      d="M3.103 9.29C1.411 9.29 0 10.008 0 12.175c0 1.717.949 2.797 3.147 2.797 2.588 0 2.754-1.705 2.754-1.705H4.647s-.269.918-1.576.918c-1.065 0-1.831-.719-1.831-1.728h4.792v-.633c0-.996-.633-2.534-2.929-2.534zm-.043.809c1.014 0 1.705.621 1.705 1.552H1.268c0-.989.902-1.552 1.792-1.552z"></path>
									<path fill="#0064D2"
									      d="M6.031 7.192v6.692c0 .38-.027.913-.027.913H7.2s.043-.383.043-.733c0 0 .591.924 2.198.924 1.692 0 2.841-1.175 2.841-2.858 0-1.566-1.056-2.825-2.838-2.825-1.669 0-2.188.901-2.188.901V7.192H6.031zm3.103 2.942c1.149 0 1.88.853 1.88 1.997 0 1.227-.844 2.03-1.871 2.03-1.226 0-1.888-.958-1.888-2.019 0-.989.593-2.008 1.879-2.008z"></path>
									<path fill="#F5AF02"
									      d="M15.254 9.29c-2.547 0-2.71 1.394-2.71 1.617h1.268s.066-.814 1.355-.814c.837 0 1.486.383 1.486 1.12v.262h-1.486c-1.973 0-3.016.577-3.016 1.748 0 1.153.964 1.78 2.266 1.78 1.775 0 2.347-.981 2.347-.981 0 .39.03.775.03.775h1.127s-.044-.476-.044-.781v-2.635c-.001-1.728-1.394-2.091-2.623-2.091zm1.398 2.972v.35c0 .456-.281 1.59-1.938 1.59-.907 0-1.296-.453-1.296-.978 0-.956 1.31-.962 3.234-.962z"></path>
									<path fill="#86B817"
									      d="M17.194 9.509h1.426l2.047 4.1 2.042-4.1H24l-3.719 7.3h-1.355l1.073-2.035-2.805-5.265z"></path>
								</svg>
							</a>
						</div>
					</div>

					<div className="mt-4 block">
						<Link to="/settings/platform-config"
						      className="text-sm font-semibold text-deepPurple hover:text-brightPurple">
							Configure More Accounts
						</Link>
					</div>
				</div>
			</div>

			{/* Log out other sessions */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Log out other sessions</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
						Please enter your password to confirm you would like to log out of your other sessions across all of your
						devices.
					</p>
				</div>

				<form className="md:col-span-2" onSubmit={handleLogoutOtherSessionsSubmit}>
					<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
						<div className="col-span-full">
							<label htmlFor="logout-password"
							       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								Your password
							</label>
							<div className="mt-2">
								<input
									id="logout-password"
									name="password"
									type="password"
									autoComplete="current-password"
									className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
					</div>

					<div className="mt-8 flex">
						<button
							type="submit"
							className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
							disabled={loading.logoutOtherSessions}
						>
							{loading.logoutOtherSessions ? (
								<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
								     viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path className="opacity-75" fill="currentColor"
									      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
								</svg>
							) : (
								'Log out other sessions'
							)}
						</button>
					</div>
				</form>
			</div>

			{/* Delete account */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Delete account</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
						No longer want to use our service? You can delete your account here. This action is not reversible. All
						information related to this account will be deleted permanently.
					</p>
				</div>

				<form className="flex items-start md:col-span-2" onSubmit={handleDeleteAccountSubmit}>
					<button
						type="submit"
						className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
						disabled={loading.deleteAccount}
					>
						{loading.deleteAccount ? (
							<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							     viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
							</svg>
						) : (
							'Yes, delete my account'
						)}
					</button>
				</form>
			</div>
			{notificationComponent}
		</div>
	);
};

export const BillingComponent = () => {
	const [showPaymentForm, setShowPaymentForm] = useState(false);
	const [invoices, setInvoices] = useState<Invoice[]>(JSON.parse(localStorage.getItem("invoices") || "null") as Invoice[] || [])
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const [loading, setLoading] = useState({
		billingInfo: false,
	});

	useEffect(() => {
		getUserInvoices()
			.then((data:any) => {
				console.log(data.message);
				setInvoices(data.data);
				window.localStorage.setItem("invoices", JSON.stringify(data.data))
			})
			.catch((err:any) => {
				console.log(err);
			})
	}, []);

	const updateUserBillingInfoRequest = async(data:any) => {
		await updateUser(userStore.user!.id, {...userStore.getUser(), ...data} as User)
			.then((res: any) => {
				if (!res.isError) {
					// @ts-ignore
					window.localStorage.setItem('user', JSON.stringify({...userStore.getUser(), ...data}))
					userStore.setUser({...userStore.getUser(), ...data} as User)
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: false,
						title: "User updated",
						message: "User information successfully updated"
					})
				} else {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating user object",
						// @ts-ignore
						message: res.error?.errorMessage | res.message
					})
				}
			})
			.catch((err: any) => {
				console.log(err);
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Error updating user object",
					message: err.message
				})
			})
	}

	const handleBillingInformationMethod = async (event: any) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, billingInfo: true}));
		const formData = new FormData(event.currentTarget);
		const data = Object.fromEntries(formData.entries());
		await updateUserBillingInfoRequest(data);
		setLoading((prev) => ({...prev, billingInfo: false}));
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	}

	const paymentCallback = async (error:any, paymentMethod:PaymentMethod|undefined) => {
		if (error) {
			console.error('[error]', error);
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: "Error adding payment method",
				message: error.toString()
			})
		} else {
			console.log('[PaymentMethod]', paymentMethod);
			// Handle the payment method as needed
			await updateUserBillingInfoRequest({paymentMethod: paymentMethod?.id});
		}
	}

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>
	}

	return (
		<div className="divide-y divide-gray-200 dark:divide-white/5">
			{/* Payment Method Update */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Payment Method</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
						Update your payment method.
					</p>
				</div>

				<div className="md:col-span-2">
					<div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-t-md shadow-sm">
						<h3 className="text-lg font-semibold text-gray-900 dark:text-white">Payment Information</h3>
						<button
							onClick={() => setShowPaymentForm(!showPaymentForm)}
							className="mt-4 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						>
							{userStore.user?.paymentMethod ? "Update" : "Add"} Payment Method
						</button>
					</div>
					{showPaymentForm && (
						<Elements stripe={stripePromise}>
							<CheckoutForm paymentCallback={paymentCallback}/>
						</Elements>
					)}
				</div>
			</div>

			{/* Update Billing Information */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Update Billing
						Information</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
						Update your billing address and other information.
					</p>
				</div>

				<form className="md:col-span-2" onSubmit={handleBillingInformationMethod}>
					<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
						<div className="sm:col-span-3">
							<label htmlFor="billingFirstName"
							       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								First name
							</label>
							<div className="mt-2">
								<input
									id="billingFirstName"
									name="billingFirstName"
									defaultValue={userStore.user?.firstName}
									type="text"
									autoComplete="billing-first-name"
									className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="sm:col-span-3">
							<label htmlFor="billingLastName"
							       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								Last name
							</label>
							<div className="mt-2">
								<input
									id="billingLastName"
									name="billingLastName"
									defaultValue={userStore.user?.lastName}
									type="text"
									autoComplete="billing-last-name"
									className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="col-span-full">
							<label htmlFor="billingAddress"
							       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								Address
							</label>
							<div className="mt-2">
								<input
									id="billingAddress"
									name="billingAddress"
									defaultValue={userStore.user?.billingAddress}
									type="text"
									autoComplete="street-address"
									className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="sm:col-span-3">
							<label htmlFor="billingCity"
							       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								City
							</label>
							<div className="mt-2">
								<input
									id="billingCity"
									name="billingCity"
									defaultValue={userStore.user?.billingCity}
									type="text"
									autoComplete="address-level2"
									className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="sm:col-span-3">
							<label htmlFor="billingState"
							       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								State
							</label>
							<div className="mt-2">
								<input
									id="billingState"
									name="billingState"
									defaultValue={userStore.user?.billingState}
									type="text"
									autoComplete="address-level3"
									className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="sm:col-span-3">
							<label htmlFor="billingZip" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								Postal code
							</label>
							<div className="mt-2">
								<input
									id="billingZip"
									name="billingZip"
									defaultValue={userStore.user?.billingZip}
									type="text"
									autoComplete="billing-zip"
									className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="sm:col-span-3">
							<label htmlFor="billingCountry"
							       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								Country
							</label>
							<div className="mt-2">
								<select
									id="billingCountry"
									name="billingCountry"
									defaultValue={userStore.user?.billingCountry}
									autoComplete="country"
									className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
								>
									<option disabled={true}>Select Country</option>
									<option value={"United States Of America"}>United States</option>
									<option value={"Canada"}>Canada</option>
									<option value={"Mexico"}>Mexico</option>
									{/* Add more countries as needed */}
								</select>
							</div>
						</div>
					</div>
					<div className="mt-8 flex">
						<button
							type="submit"
							className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						>
							{loading.billingInfo ? (
								<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
								     viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path className="opacity-75" fill="currentColor"
									      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
								</svg>
							) : (
								'Save'
							)}
						</button>
					</div>
				</form>
			</div>

			{/* Billing History */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Billing History</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
						View your past invoices.
					</p>
				</div>

				<div className="md:col-span-2">
					{invoices.length > 0 ? (
						<div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-md shadow-sm">
							<ul className="divide-y divide-gray-200 dark:divide-gray-700">
								{invoices.map((invoice) => (
									<li key={invoice.id} className="py-4 flex justify-between items-center">
										<span className="text-sm text-gray-600 dark:text-gray-400">Invoice #{invoice.id}</span>
										<span className="text-sm text-gray-600 dark:text-gray-400">{invoice.currency} {invoice.amount_due}</span>
										<span className="text-sm text-gray-600 dark:text-gray-400">{new Date(invoice.period_start * 1000).toLocaleDateString()}</span>
										<span className="text-sm text-gray-600 dark:text-gray-400">{new Date(invoice.period_start * 1000).toLocaleDateString()}</span>
										<span className="text-sm text-gray-600 dark:text-gray-400">{invoice.status}</span>
										<a href={invoice.invoice_pdf} target={"_blank"} className="text-sm text-indigo-500 hover:underline">Download</a>
									</li>
								))}
							</ul>
						</div>
					) : (
						<div className="flex flex-col items-center justify-center">
							<img src={invoiceImage} alt="No Team Members" className="mb-8 w-80 h-80"/>
							<h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">No Invoice Generated yet!</h1>
							<p className="mt-4 text-lg text-gray-700 dark:text-gray-300">
								Kindly wait till subscription period has elapsed.
							</p>
						</div>
					)}
				</div>
			</div>

			{notificationComponent}
		</div>
	);
};

export const PlansAndAddonsComponent = () => {
	const [selectedAddons, setSelectedAddons] = useState<Plan[]>(userStore.user?.addons || []);
	const [showDialog, setShowDialog] = useState(false);
	const [loading, setLoading] = useState({
		planChange: false,
		addonSave: false,
	});
	const [currentPlan, setCurrentPlan] = useState({ name: userStore.user?.plan?.name, description: userStore.user?.plan?.description, id: userStore.user?.plan?.id });
	const [selectedPlan, setSelectedPlan] = useState<any>(userStore.user?.plan);
	const [enabled, setEnabled] = useState(userStore.user?.plan?.interval.toLowerCase() === "year");
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	const getPriceDisplay = (price: any) => {
		if (typeof price === 'string') {
			return price;
		}
		const formattedPrice = enabled ? (price * 12 * 0.8).toFixed(2) : price.toFixed(2);
		return `$${formattedPrice}`;
	};

	const getFullPriceDisplay = (price: any) => {
		if (typeof price === 'string') {
			return ""
		}
		return `$${(price * 12).toFixed(2)}`;
	};

	const handleToggleAddon = (addon:Plan) => {
		addon["date"] = +new Date();
		addon["interval"] = enabled ? "year" : "month";
		// @ts-ignore
		// addon["price"] = getAddonPriceId(addon.id, enabled ? "year" : "month")
		setSelectedAddons((prevSelectedAddons) => prevSelectedAddons.some((a) => a.id === addon.id) ? prevSelectedAddons.filter((a) => a.id !== addon.id) : [...prevSelectedAddons, addon]);
	};

	const enableIntervalChange = () => {
		setSelectedAddons([]);
		// if the user is changing from yearly to monthly
		if(enabled && userStore.user?.plan?.interval === "month") {
			setSelectedAddons(userStore.user.addons || []);
		}
		// if the user is changing from monthly to yearly
		if(!enabled && userStore.user?.plan?.interval === "year") {
			setSelectedAddons(userStore.user.addons || []);
		}
		setEnabled(!enabled);
	}

	const handleSaveAddons = async () => {
		setLoading((prev) => ({...prev, addonSave: true}));
		const addons = [...selectedAddons.map((add) => ({...add, price: getAddonPriceId(add.id, enabled ? "year" : "month")}) )]
		await updateUserAddons(addons)
			.then((res: any) => {
				if (!res.isError) {
					// @ts-ignore
					window.localStorage.setItem('user', JSON.stringify({...userStore.getUser(), addons}))
					userStore.setUser({...userStore.getUser(), addons} as User)
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: false,
						title: "User updated",
						message: "User information successfully updated"
					})
				} else {
					// @ts-ignore
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating user object",
						// @ts-ignore
						message: res.error?.errorMessage | res.message
					})
				}
			})
			.catch((err: any) => {
				console.log(err);
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Error updating user object",
					message: err.message
				})
			})
		setLoading((prev) => ({...prev, addonSave: false}));
	};

	const handlePlanButtonClick = (plan: any) => {
		setCurrentPlan({name: plan.name, description: plan.description, id: plan.id});
		setShowDialog(true);
	};

	const handlePlanChange = async () => {
		setLoading((prev) => ({...prev, planChange: true}));
		const updatedPlan = {
			...currentPlan,
			date: +new Date(),
			interval: enabled ? "year" : "month",
			price: getPriceId(currentPlan.name || "Starter", enabled ? "year" : "month")
		};
		const addons = selectedAddons.filter((addon) => !addonMapping[addon.id].includes(updatedPlan.name || "Starter"));
		// Filter out redundant addons
		// setSelectedAddons((prevSelectedAddons) => prevSelectedAddons.filter((addon) => !addonMapping[addon.id].includes(updatedPlan.name || "Starter")));
		await updatePlan({ plan: updatedPlan, addons: [...addons.map((add) => ({...add, price: getAddonPriceId(add.id, enabled ? "year" : "month")}) )] })
			.then((res: any) => {
				if (!res.isError) {
					setSelectedPlan(updatedPlan);
					setSelectedAddons(addons);
					window.localStorage.setItem('user', JSON.stringify({...userStore.getUser(), plan: updatedPlan, addons: [...addons.map((add) => ({...add, price: getAddonPriceId(add.id, enabled ? "year" : "month")}) )]}))
					userStore.setUser({...userStore.getUser(), plan: updatedPlan, addons: [...addons.map((add) => ({...add, price: getAddonPriceId(add.id, enabled ? "year" : "month")}) )]} as User)
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: false,
						title: "User plan updated",
						message: "User plan successfully updated"
					})
				} else {
					// @ts-ignore
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating user plan",
						// @ts-ignore
						message: res.error?.errorMessage | res.message
					})
				}
			})
			.catch((err: any) => {
				console.log(err);
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Error updating user plan",
					message: err.message
				})
			})
		setLoading((prev) => ({...prev, planChange: false}));
	}

	const handleCloseDialog = () => {
		setShowDialog(false);
	};

	const getPlanButtonLabel = (planId: string, price:string) => {
		if (planId === selectedPlan.id && selectedPlan.price === price) return 'View Plan';
		const currentPlanIndex = tiers.findIndex((tier) => tier.id === selectedPlan.id);
		const planIndex = tiers.findIndex((tier) => tier.id === planId);
		return planIndex > currentPlanIndex ? 'Upgrade Plan' : planIndex === currentPlanIndex ? 'Change Plan' : 'Downgrade Plan';
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	}

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>
	}

	return (
		<div className="divide-y divide-gray-200 dark:divide-white/5">
			{/* Plans */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Plans</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
						Choose a plan that fits your needs and add additional features as required.
					</p>
				</div>
				<div className="md:col-span-2">
					<Field className="flex mb-6 items-center space-x-4 place-content-center">
						<Label as="span" className="ml-3 text-sm">
							<span className="font-medium text-gray-900 dark:text-gray-100">Monthly</span>
						</Label>
						<Switch
							checked={enabled}
							onChange={enableIntervalChange}
							className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
						>
        <span
	        aria-hidden="true"
	        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
        />
						</Switch>
						<Label as="span" className="ml-3 text-sm">
							<span className="font-medium text-gray-900 dark:text-gray-100">Annual billing</span>{' '}
							<span className="text-gray-500">(Save 20%)</span>
						</Label>
					</Field>

					<div className="space-y-6">
						{tiers.map((tier) => (
							<div key={tier.id} className="bg-gray-100 dark:bg-gray-800 p-4 rounded-md shadow-sm">
								<h3 className="text-lg font-semibold text-gray-900 dark:text-white">{tier.name}</h3>
								<p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{tier.description}</p>
								<p className="mt-2 text-sm flex items-baseline relative gap-x-1 text-gray-600 dark:text-gray-400">
									<span className="text-2xl font-bold">{getPriceDisplay(tier.priceMonthly)}</span>
									{
										enabled && (
											<span className="text-gray-500 line-through left-0">{getFullPriceDisplay(tier.priceMonthly)}</span>
										)
									}
									{
										tier.name.toLowerCase() !== "enterprise" &&
										<span className="text-sm font-semibold">{enabled ? "/year" : "/month"}</span>
									}
								</p>
								<button
									onClick={() => handlePlanButtonClick(tier)}
									className="mt-4 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
								>
									{getPlanButtonLabel(tier.id, getPriceId(tier.name, enabled ? "year" : "month"))}
								</button>
							</div>
						))}
					</div>
				</div>
			</div>

			{/* Add-Ons */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Add-Ons</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
						Select additional features to enhance your subscription.
					</p>
				</div>

				<div className="md:col-span-2">
					<div className="space-y-6">
						{addons.map((addon) => (
							<div key={addon.id} className="bg-gray-100 dark:bg-gray-800 p-4 rounded-md shadow-sm">
								<h3 className="text-lg font-semibold text-gray-900 dark:text-white">{addon.name}</h3>
								<p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{addon.description}</p>
								<p className="mt-2 text-sm flex items-baseline relative gap-x-1 text-gray-600 dark:text-gray-400">
									<span className="text-2xl font-bold">{getPriceDisplay(addon.price)}</span>
									{
										enabled && (
											<span className="text-gray-500 line-through left-0">{getFullPriceDisplay(addon.price)}</span>
										)
									}
									{
										<span className="text-sm font-semibold">{enabled ? "/year" : "/month"}</span>
									}
								</p>
								{/*@ts-ignore*/}
								<button onClick={addonMapping[addon.id].includes(selectedPlan.name) ? undefined : () => handleToggleAddon(addon)}
									className={`mt-4 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
										addonMapping[addon.id].includes(selectedPlan.name) ?
											`bg-lavender !cursor-auto` :
										// 	@ts-ignore
										selectedAddons.map((ad) => ad.id).includes(addon.id)
											? 'bg-red-500 hover:bg-red-400'
											: 'bg-indigo-500 hover:bg-indigo-400'
									} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500`}
								>
									{/*@ts-ignore*/}
									{addonMapping[addon.id].includes(selectedPlan.name) ? `Included in ${selectedPlan.name} Plan` : selectedAddons.map(ad => ad.id).includes(addon.id) ? 'Remove Add-On' : 'Add Add-On'}
								</button>
							</div>
						))}
					</div>

					<div className="mt-8 flex w-full justify-center">
						<button
							onClick={handleSaveAddons}
							disabled={loading.addonSave}
							className="rounded-md block w-full bg-indigo-500 flex justify-center text-center px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						>
							{loading.addonSave ? (
								<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
								     viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path className="opacity-75" fill="currentColor"
									      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
								</svg>
							) : (
								"Save Add-Ons"
							)}
						</button>
					</div>
				</div>
			</div>

			{/* Plan Details Dialog */}
			<Transition appear show={showDialog} as={React.Fragment}>
				<Dialog as="div" className="relative z-[100]" onClose={handleCloseDialog}>
					<Transition.Child
						as={React.Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25"/>
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={React.Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel
									className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
									<div className="flex justify-between items-center">
										<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
											{currentPlan.name} Plan ({enabled ? "Yearly" : "Monthly"})
										</Dialog.Title>
										<button
											type="button"
											className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
											onClick={handleCloseDialog}
										>
											<XMarkIcon className="h-6 w-6"/>
										</button>
									</div>
									<div className="mt-2">
										<p className="text-sm text-gray-500 dark:text-gray-400">{currentPlan.description}</p>
										<div className="mt-4">
											{sections.map((section) => (
												<div key={section.name} className="mb-4">
													<h4 className="text-md font-semibold text-gray-900 dark:text-white">{section.name}</h4>
													<ul className="mt-2 space-y-1">
														{section.features.map((feature) => (
															<li key={feature.name} className="text-sm text-gray-600 dark:text-gray-400">
																<span className="font-medium text-gray-900 dark:text-white">{feature.name}: </span>
																{/*@ts-ignore*/}
																{(feature.tiers[currentPlan.name] && feature.tiers[currentPlan.name].toString()) || "Addon"}
															</li>
														))}
													</ul>
												</div>
											))}
										</div>
									</div>

									<div className="mt-4">
										<button
											type="button"
											className="inline-flex justify-center text-center rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
											disabled={loading.planChange}
											onClick={selectedPlan?.price === getPriceId(currentPlan.name || "Starter", enabled ? "year" : "month") ? handleCloseDialog : async (e: any) => {
												e.preventDefault();
												await handlePlanChange().catch(err => {console.log(err)});
												handleCloseDialog();
											}}
										>
											{loading.planChange ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
												     viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor"
													      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												selectedPlan?.price === getPriceId(currentPlan.name || "Starter", enabled ? "year" : "month") ? 'Close' : 'Choose Plan'
											)}
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>

			{ notificationComponent }
		</div>
	);
};

export const TeamsComponent = () => {
	const [teamMembers, setTeamMembers] = useState(teams);
	const [inviteEmail, setInviteEmail] = useState('');
	const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});
	const [loadingDelStates, setLoadingDelStates] = useState<{ [key: string]: boolean }>({});
	const [loading, setLoading] = useState({
		teamInvite: false,
	});

	const handleRemoveUser = async (email: string) => {
		setLoadingDelStates((prev) => ({...prev, [email]: true}));
		try {
			// Simulate API call
			await new Promise((resolve) => setTimeout(resolve, 2000));
			setTeamMembers((prev) => prev.filter((member) => member.email !== email));
		} catch (error) {
			console.error('Error removing user:', error);
		} finally {
			setLoadingDelStates((prev) => ({...prev, [email]: false}));
		}
	};

	const handleResendInvite = async (email: string) => {
		setLoadingStates((prev) => ({...prev, [email]: true}));
		try {
			// Simulate API call
			await new Promise((resolve) => setTimeout(resolve, 2000));
			console.log(`Invite resent to ${email}`);
		} catch (error) {
			console.error('Error resending invite:', error);
		} finally {
			setLoadingStates((prev) => ({...prev, [email]: false}));
		}
	};

	const handleInviteSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, teamInvite: true}));
		try {
			// Simulate API call to invite a team member
			await new Promise((resolve) => setTimeout(resolve, 2000));
			setTeamMembers([...teamMembers, {
				email: inviteEmail,
				name: 'New Member',
				dateJoined: new Date().toISOString().split('T')[0],
				lastLogin: '',
				inviteState: 'pending',
			}]);
			setInviteEmail('');
		} catch (error) {
			console.error('Error inviting team member:', error);
		} finally {
			setLoading((prev) => ({...prev, teamInvite: false}));
		}
	};

	return (
		<div className="divide-y divide-gray-200 dark:divide-white/5">
			{/* Invite Team Members */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Invite Team Members</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
						Invite new team members by entering their email address.
					</p>
				</div>

				<form className="md:col-span-2" onSubmit={handleInviteSubmit}>
					<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
						<div className="col-span-full">
							<label htmlFor="invite-email"
							       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
								Email address
							</label>
							<div className="mt-2">
								<input
									id="invite-email"
									name="invite-email"
									type="email"
									value={inviteEmail}
									onChange={(e) => setInviteEmail(e.target.value)}
									autoComplete="email"
									className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
									required
								/>
							</div>
						</div>
					</div>

					<div className="mt-8 flex">
						<button
							type="submit"
							className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
							disabled={loading.teamInvite}
						>
							{loading.teamInvite ? (
								<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
								     viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path className="opacity-75" fill="currentColor"
									      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
								</svg>
							) : (
								'Send Invite'
							)}
						</button>
					</div>
				</form>
			</div>

			{/* Team Members */}
			<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
				<div>
					<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Team Members</h2>
					<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
						View and manage your team members.
					</p>
				</div>

				<div className="md:col-span-2">
					{teamMembers.length > 0 ? (
						<div className="overflow-x-auto">
							<table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
								<thead className="bg-gray-50 dark:bg-gray-800">
								<tr>
									<th scope="col"
									    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
										Email
									</th>
									<th scope="col"
									    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
										Name
									</th>
									<th scope="col"
									    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
										Date Joined
									</th>
									<th scope="col"
									    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
										Last Login
									</th>
									<th scope="col"
									    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
										Actions
									</th>
								</tr>
								</thead>
								<tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
								{teamMembers.map((member, index) => (
									<tr key={index}>
										<td
											className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{member.email}</td>
										<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{member.name}</td>
										<td
											className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{member.dateJoined}</td>
										<td
											className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{member.lastLogin}</td>
										<td className="px-6 py-4 flex whitespace-nowrap text-sm text-gray-900 dark:text-white">
											{member.inviteState === 'pending' && (
												<button
													onClick={() => handleResendInvite(member.email)}
													className="mr-2 rounded-md bg-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
													disabled={loadingStates[member.email]}
												>
													{loadingStates[member.email] ? (
														<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
														     fill="none" viewBox="0 															0 24 24">
															<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
															        strokeWidth="4"></circle>
															<path className="opacity-75" fill="currentColor"
															      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
														</svg>
													) : (
														'Resend Invite'
													)}
												</button>
											)}
											{member.inviteState === 'rejected' && (
												<button
													onClick={() => handleResendInvite(member.email)}
													className="mr-2 rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
													disabled={loadingStates[member.email]}
												>
													{loadingStates[member.email] ? (
														<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
														     fill="none" viewBox="0 24 24">
															<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
															        strokeWidth="4"></circle>
															<path className="opacity-75" fill="currentColor"
															      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
														</svg>
													) : (
														'Resend Invite'
													)}
												</button>
											)}
											<button
												onClick={() => handleRemoveUser(member.email)}
												className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
												disabled={loadingDelStates[member.email]}
											>
												{loadingDelStates[member.email] ? (
													<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
													     fill="none" viewBox="0 0 24 24">
														<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														        strokeWidth="4"></circle>
														<path className="opacity-75" fill="currentColor"
														      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
													</svg>
												) : (
													<TrashIcon className="h-5 w-5"/>
												)}
											</button>
										</td>
									</tr>
								))}
								</tbody>
							</table>
						</div>
					) : (
						<div className="flex flex-col items-center justify-center">
							<img src={userImage} alt="No Team Members" className="mb-8 w-80 h-80"/>
							<h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">No Team Members</h1>
							<p className="mt-4 text-lg text-gray-700 dark:text-gray-300">
								You have not added any team members yet.
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default function Profile() {
	const [name, setName] = useState("Account");

	return (
		<main>
			<h1 className="sr-only">Account Settings</h1>
			<header className="border-b dark:border-gray-700 border-gray-200">
				{/* Secondary navigation */}
				<nav className="flex overflow-x-auto py-4">
					<ul
						role="list"
						className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
					>
						{secondaryNavigation.map((item) => (
							<li key={item.name}>
								<Link to={item.href} onClick={() => setName(item.name)}
								      className={name === item.name ? 'text-indigo-400' : ''}>
									{item.name}
								</Link>
							</li>
						))}
					</ul>
				</nav>
			</header>
			<Routes>
				<Route path="/" element={<AccountComponent/>}/>
				<Route path="/billing" element={<BillingComponent/>}/>
				<Route path="/plan" element={<PlansAndAddonsComponent/>}/>
				<Route path="/teams" element={<TeamsComponent/>}/>
			</Routes>
		</main>
	);
}

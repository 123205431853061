import { makeAutoObservable } from "mobx";
import { SalesRecord } from "app-ts-types/sales";
import { MarketplacePlatform } from "app-ts-types/platforms";
import {salesRecords} from "./data"


class SalesRecordsStore {
	salesRecords: SalesRecord[] = []; // salesRecords;
	filter: string = "";
	sortOption: { field: string; direction: string } = { field: "", direction: "" };
	currentPage: number = 1;
	itemsPerPage: number = 100;
	selectedPlatform: MarketplacePlatform | "" = "";

	constructor() {
		makeAutoObservable(this);
	}

	setSalesRecords(salesRecords: SalesRecord[]) {
		this.salesRecords = salesRecords;
	}

	addSalesRecords(newItems: SalesRecord[]) {
		this.salesRecords = [...this.salesRecords, ...newItems];
	}

	updateSalesRecord(updatedItem: SalesRecord) {
		const index = this.salesRecords.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.salesRecords[index] = updatedItem;
		}
	}

	deleteSalesRecord(itemId: string) {
		this.salesRecords = this.salesRecords.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	setSelectedPlatform(platform: MarketplacePlatform | "") {
		this.selectedPlatform = platform;
	}

	get filteredAndSortedSalesRecords(): SalesRecord[] {
		let filteredSalesRecords = this.salesRecords;

		if (this.filter) {
			filteredSalesRecords = filteredSalesRecords.filter(item =>
				item.productTitle.includes(this.filter)
			);
		}

		if (this.selectedPlatform !== "") {
			filteredSalesRecords = filteredSalesRecords.filter(item =>
				item.platform === this.selectedPlatform
			);
		}

		if (this.sortOption.field) {
			filteredSalesRecords = filteredSalesRecords.sort((a, b) => {
				if (this.sortOption.direction === "ASCENDING") {
					// @ts-ignore
					return a[this.sortOption.field] > b[this.sortOption.field] ? 1 : -1;
				} else {
					// @ts-ignore
					return a[this.sortOption.field] < b[this.sortOption.field] ? 1 : -1;
				}
			});
		}

		return filteredSalesRecords;
	}

	get paginatedSalesRecords(): SalesRecord[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedSalesRecords.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedSalesRecords.length / this.itemsPerPage);
	}
}

export const salesRecordsStore = new SalesRecordsStore();

import React from 'react';
import noSalesImage from "../images/no-sales-made.png";
import EmptyComponent from "../components/Empty";
import { salesRecords } from "../state/data"; // Assuming the sales data is imported from here
import {marketplacePlatformToJSON, MarketplacePlatform, marketplacePlatformFromJSON} from "../utils/types";
import {marketplaceImages} from "../components/ListingImageCloud";
import {observer} from "mobx-react-lite";

const Sales = observer(() => {
	const totalSales = salesRecords.length;

	// Calculate sales per platform
	const salesByPlatform = salesRecords.reduce((acc, record) => {
		const platformName = marketplacePlatformToJSON(record.platform);
		// @ts-ignore
		acc[platformName] = (acc[platformName] || 0) + 1;
		return acc;
	}, {});

	// @ts-ignore
	const sortedPlatforms = Object.entries(salesByPlatform).sort((a, b) => b[1] - a[1]);

	const component = totalSales === 0 ? (
		<EmptyComponent
			imageSrc={noSalesImage}
			headerText={"Sales"}
			descriptionText={"You have made no sales yet!"}
		/>
	) : (
		<div className="lg:pl-4">
			<h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4">Total Sales: {totalSales}</h2>

			{/* Sales Records List */}
			{/* Sales Records Grid */}
			<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 lg:p-4">
				{salesRecords.map((record) => (
					<div key={record.id} className="flex flex-col border cursor-pointer dark:border-gray-700 overflow-hidden bg-gray-200 dark:bg-gray-800 rounded-lg shadow-md">
						{/* Listing Image */}
						<div className="h-48 w-full bg-cover bg-center" style={{ backgroundImage: `url(${record.imageUrl || encodeURI(`https://placehold.co/150x200/C4B5FD/7B5EFF?text=${record.productTitle}&font=oswald`)})` }} title={record.productTitle}></div>

						{/* Sales Record Details */}
						<div className="p-4 flex-grow border-t border-gray-500">
							<p className="text-lg font-medium text-gray-800 dark:text-gray-200">{record.productTitle}</p>
							<p className="text-gray-600 dark:text-gray-400">
								Price: {record.price.toFixed(2)} {record.currencyCode}
							</p>
							<p className="text-gray-600 dark:text-gray-400">
								Platform: {marketplacePlatformToJSON(record.platform)}
							</p>
							<p className="text-gray-600 dark:text-gray-400">
								Sale Date: {new Date(record.saleTimestamp).toLocaleDateString()}
							</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);

	return (
		<div>
			<h1 className={"text-2xl font-bold mb-10"}>Product Sales</h1>
			<div className="flex flex-col lg:flex-row gap-4 min-h-screen dark:bg-gray-900">
				{/* Sidebar with Platform Analytics */}
				<aside className="lg:w-1/4 lg:border-r border-gray-200 dark:border-gray-700 pr-4 mb-4 md:mb-0">
					<h3 className="text-lg font-semibold mb-4 text-gray-800 dark:text-gray-200">Sales by Platform</h3>
					<ul className="space-y-2">
						{sortedPlatforms.length > 0 ? (
							sortedPlatforms.map(([platform, count]: any) => (
								// <li key={platform} className="flex justify-between text-gray-600 dark:text-gray-300">
								// 	<span>{platform}</span>
								// 	{/*@ts-ignore*/}
								// 	<span>{count}</span>
								// </li>
							<li key={platform}
							    className="border cursor-pointer dark:border-gray-700 overflow-hidden bg-gray-200 dark:bg-gray-800 px-4 py-4 shadow sm:rounded-md sm:px-2 flex justify-between">
								<div className="flex items-center space-x-2">
									<img src={marketplaceImages[marketplacePlatformFromJSON(platform)].src} alt={marketplaceImages[marketplacePlatformFromJSON(platform)].alt} className="h-6 w-6 rounded-full"/>
									<span className="text-gray-900 dark:text-gray-100">{marketplaceImages[marketplacePlatformFromJSON(platform)].alt}</span>
								</div>
								<div>{count}</div>
							</li>
							))
						) : (
							<p className="text-gray-500 dark:text-gray-400">No sales data available</p>
						)}
					</ul>
				</aside>

				{/* Main Content */}
				<main className="flex-grow">
					{component}
					{/*<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">*/}
					{/*</div>*/}
				</main>
				</div>
		</div>
	);
});

export default Sales;
